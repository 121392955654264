import React from 'react';
import Facade from '../Datafacade/datafacade';
import { NavLink } from 'react-router-dom';
import Loader from 'react-loader-spinner';
import { usePromiseTracker } from "react-promise-tracker";
import { FormControl } from "react-bootstrap";
import PlayersSearch from "../playerSearch/playersSearch";
const LoadingIndicator = props => {
    const { promiseInProgress } = usePromiseTracker();
    return promiseInProgress &&
        <div
            style={{
                width: "100%",
                height: "100",
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
            }}
        >
            <Loader type="TailSpin" color="#2BAD60" height="100" width="100" />
        </div>
};

class Leaderboard extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            players: [],
            breakload: true,
            scrollListener: {},
            keyword: "",
            searchBool: true,
        };
        this.scrollListener = this.handleScroll.bind(this);
    }

    handleKeywordInput = e => {
        const searchTerm = e.target.value;
        this.setState({ keyword: searchTerm });
        this.setState({ searchBool: true });
    };

    handleSubmit = e => {
        e.preventDefault();
        this.setState({ searchBool: false });
    };

    componentWillMount() {
        window.addEventListener('scroll', this.scrollListener);
    }

    componentWillUnmount() {
        window.removeEventListener("scroll", this.scrollListener);
    }

    componentDidMount = async () => {
        this.setState({ breakload: false })
        const players = await Facade.getLeaderBoardFromCache(0);
        this.setState({ breakload: true })
        this.setState({ players });
    };

    findLateIndex(array) {
        return array[array.length - 5];
    }

    handleScroll = () => {
        if (this.state.breakload) {
            const player = this.findLateIndex(this.state.players);
            var lastLi = document.getElementById('leaderboard/' + player.steamID);
            var lastLiOffset = lastLi.offsetTop + lastLi.clientHeight;
            var pageOffset = window.pageYOffset + window.innerHeight;
            if (pageOffset > lastLiOffset) {
                this.setState({ breakload: false })
                this.loadPlayers();
            }
        }
    };

    loadPlayers = async () => {
        const players = await Facade.getLeaderBoardFromCache(this.state.players.length + 1);
        this.setState({ players });
        this.setState({ breakload: true })
    }

    render() {
        return (
            <React.Fragment>
                <section className="hero_single version_2 start_bg_zoom">
                    <div className="wrapper">
                        <div className="container">
                            <h3>UNLOZE Zombie Escape LeaderBoard</h3>
                            <p>
                                Browse LeaderBoard Players
              				</p>
                            <form>
                                <div className="row no-gutters custom-search-input-2">
                                    <div className="col-12 col-md-8">
                                        <div className="form-group">
                                            <FormControl
                                                type="text"
                                                onChange={this.handleKeywordInput}
                                                value={this.state.k}
                                                placeholder="Search Players by name or SteamID"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-4">
                                        <input
                                            type="submit"
                                            onClick={this.handleSubmit}
                                            className="btn_search text-center"
                                            value="Search"
                                        />
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </section>
                <div className="tickets-block" style={{ background_color: "#393f47" }}>
                    <div className="container-fluid">
                        <p className="text-center ">
                            <NavLink to={'/mapboard/'}>
                                <b>MapBoard</b>
                            </NavLink>
                        </p>
                    </div>
                </div>
                {!this.state.breakload ? <h1>
                    <LoadingIndicator />
                </h1> : ""}
                {this.state.searchBool ? ("") : (<PlayersSearch keyword={this.state.keyword} />)}
                <div className="container mm">
                    <div className="row">
                        {this.state.players.map(e => (
                            <div className="item col-12 col-sm-6 col-md-4 col-lg-3 d-flex justify-content-center" key={e.steamID} id={"leaderboard/" + e.steamID}>
                                <div className="box_grid text-center">
                                    <NavLink to={'/player/' + e.steamID}>
                                        <strong> {e.steamID}</strong><br />
                                        <strong>{e.name}</strong><br />
                                        <figure>
                                            <img src={e.Avatar} className="img-fluid" alt={e.Avatar} /> <br />
                                            <div className="read_more">
                                                <span>Check Player</span>
                                            </div>
                                        </figure>
                                        <strong>Rank: {e.Rank}</strong><br />
                                        <strong>Points: {e.PlayerPoints}</strong><br />
                                        <strong>Times: {e.Times}</strong><br />
                                        <div id="test">
                                            {e.UrlBanners.map(banners => (
                                                <img src={banners} alt="" key={banners} />
                                            ))}
                                        </div>
                                        <br />
                                        <br />
                                    </NavLink>

                                </div> </div>
                        ))}
                    </div> </div>
            </React.Fragment>
        );
    }
}


export default Leaderboard;