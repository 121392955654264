import React from 'react';
import Facade from '../Datafacade/datafacade';
import { NavLink } from 'react-router-dom';
import Loader from 'react-loader-spinner';
import { usePromiseTracker } from "react-promise-tracker";
import '../css/style.css';

const LoadingIndicator = props => {
    const { promiseInProgress } = usePromiseTracker();
    return promiseInProgress &&
        <div
            style={{
                width: "100%",
                height: "100",
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
            }}
        >
            <Loader type="TailSpin" color="#2BAD60" height="100" width="100" />
        </div>
};

class Maps extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            mapTimes: [],
            mapName: '',
            breakload: true,
            scrollListener: {},
            fetchsize: 0,
        };
        this.scrollListener = this.handleScroll.bind(this);
    }

    componentWillMount = async () => {
        window.addEventListener('scroll', this.scrollListener);
        this.setState({ breakload: false })
        const mapname = this.props.match.params.mapname;
        const stage = this.props.match.params.stage;
        try {
            const mapTimes = await Facade.getMapTimesFromCache(mapname, stage, 0);
            const fetchsize = await Facade.getMapTimesFromCacheSize(mapname, stage);
            this.setState({ mapTimes });
            this.setState({ fetchsize });
        } catch (error) {
            console.log("invalid map or stage");
        }
        this.setState({ mapName: mapname })
        this.setState({ breakload: true })
    };

    componentWillUnmount() {
        window.removeEventListener("scroll", this.scrollListener);
    }

    findLateIndex(array) {
        return array[array.length - 5];
    }

    handleScroll = () => {
        if (this.state.breakload && this.state.fetchsize > this.state.mapTimes.length) {
            const player = this.findLateIndex(this.state.mapTimes);
            var lastLi = document.getElementById('maps/' + player.steamID);
            var lastLiOffset = lastLi.offsetTop + lastLi.clientHeight;
            var pageOffset = window.pageYOffset + window.innerHeight;
            if (pageOffset > lastLiOffset) {
                this.setState({ breakload: false })
                this.loadPlayers();
            }
        }
    };

    loadPlayers = async () => {
        const mapname = this.props.match.params.mapname;
        const stage = this.props.match.params.stage;
        const mapTimes = await Facade.getMapTimesFromCache(mapname, stage, this.state.mapTimes.length);
        this.setState({ mapTimes });
        this.setState({ breakload: true })
    }

    render() {
        return (
            <React.Fragment>
                <section className="hero_single version_2 start_bg_zoom">
                    <div className="wrapper">
                        <div className="container">
                            <h3>Map: {this.state.mapName}</h3>
                            <p>
                                Stage: {this.props.match.params.stage}
                            </p>
                        </div>
                    </div>
                </section>
                <div className="tickets-block" style={{ background_color: "#393f47" }}>
                    <div className="container-fluid">
                        <p className="text-center ">
                            <NavLink to={'/mapboard/'}>
                                <b>MapBoard</b>
                            </NavLink>
                        </p>
                        <p className="text-center ">
                            <NavLink to={'/leaderboard/'}>
                                <b>LeaderBoard</b>
                            </NavLink>
                        </p>
                    </div>
                </div>
                <div className="container mm">
                    <div className="row">
                        {this.state.mapTimes.map(e => (
                            <div className="item col-12 col-sm-6 col-md-4 col-lg-3 d-flex justify-content-center" key={e.steamID} id={"maps/" + e.steamID} >
                                <div className="box_grid text-center">
                                    <NavLink to={'/player/' + e.steamID}>
                                        <strong> SteamID: {e.steamID}</strong><br />
                                        <strong > Name: {e.name}</strong><br />
                                        <img src={e.avatar} alt="" /> <br />
                                    </NavLink>
                                    <strong> Position: {e.position}</strong><br />
                                    <strong> Mappoints: {e.mapPoint}</strong><br />
                                    <strong> Time: {e.mapTime}</strong><br />
                                    {Object.keys(e.badgesUrls).map(badges => (
                                        <div key={e.badgesUrls[badges]}>
                                            <img src={e.badgesUrls[badges]} alt="" /> <br />
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ))}
                        {!this.state.breakload ? <h1>
                            <LoadingIndicator />
                        </h1> : this.state.fetchsize === 0 ? <h1>
                            No data found
                        </h1> : ""}
                    </div>
                </div >
            </React.Fragment >
        )
    }
}


export default Maps;