import React from 'react';
import { trackPromise } from 'react-promise-tracker';
const URLleaderboard = "https://racebackend.unloze.com/racetimer_endpoints-1.0/api/timers/leaderboard";
const URLPlayer = "https://racebackend.unloze.com/racetimer_endpoints-1.0/api/timers/player/";
const URLPlayerMaps = "https://racebackend.unloze.com/racetimer_endpoints-1.0/api/timers/player/maps/";
const URLMaps = "https://racebackend.unloze.com/racetimer_endpoints-1.0/api/timers/map/";
const URLAllMaps = "https://racebackend.unloze.com/racetimer_endpoints-1.0/api/timers/allmaps";
const URLMapsSize = "https://racebackend.unloze.com/racetimer_endpoints-1.0/api/timers/mapsizecache";
const URLSearchPlayers = "https://racebackend.unloze.com/racetimer_endpoints-1.0/api/timers/searchplayers/";
const URLSearchMaps = "https://racebackend.unloze.com/racetimer_endpoints-1.0/api/timers/searchmaps/";

function handleHttpErrors(res) {
  if (!res.ok) {
    return Promise.reject({ status: res.status, fullError: res.json() });
  }
  return res.json();
}

class DataFacade extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    };
  }

  getLeaderBoardFromCache = async (incrementer) => {
    const CachedTime = JSON.parse(localStorage.getItem("leaderboardTimer"));
    const timeExpired = CachedTime ? Date.now() - CachedTime : 0;
    //30 mins
    if (!CachedTime || timeExpired > 30 * 60000) {
      localStorage.setItem("leaderboardTimer", JSON.stringify(Date.now()));
      localStorage.removeItem("leaderboard");
      incrementer = 0;
    }
    var leaderBoardData = localStorage.getItem("leaderboard");
    if (incrementer === 0 && leaderBoardData) {
      const cachedLeaderBoard = JSON.parse(leaderBoardData);
      return cachedLeaderBoard;
    } else {
      const players = await trackPromise(fetch(URLleaderboard + "/" + incrementer).then(handleHttpErrors));
      if (leaderBoardData !== null) {
        const cachedLeaderBoard = JSON.parse(leaderBoardData);
        if (players === null) {
          return cachedLeaderBoard;
        }
        players.map(e => (
          cachedLeaderBoard.push(e)
        ))
        localStorage.setItem("leaderboard", JSON.stringify(cachedLeaderBoard));
        return cachedLeaderBoard;

      } else if (incrementer === 0) {
        localStorage.setItem("leaderboard", JSON.stringify(players));
        return players;
      }
    }
  }


  getMapCountFromCache = async () => {
    const CachedTime = JSON.parse(localStorage.getItem("allmapsTimer"));
    const timeExpired = CachedTime ? Date.now() - CachedTime : 0;
    //30 mins
    if (!CachedTime || timeExpired > 30 * 60000) {
      localStorage.setItem("allmapsTimer", JSON.stringify(Date.now()));
      localStorage.removeItem("allmaps");
    }
    var allMapsData = localStorage.getItem("allmaps");
    if (allMapsData) {
      const cachedAllMaps = JSON.parse(allMapsData);
      return cachedAllMaps;
    } else {
      const maps = await trackPromise(fetch(URLAllMaps).then(handleHttpErrors));
      localStorage.setItem("allmaps", JSON.stringify(maps));
      return maps;
    }
  }

  getPlayerBadgesFromCache = async (steamID) => {
    const CachedTime = JSON.parse(localStorage.getItem("initialBadgesCachedplayers" + steamID));
    const timeExpired = CachedTime ? Date.now() - CachedTime : 0;
    //30 mins
    if (!CachedTime || timeExpired > 30 * 60000) {
      localStorage.setItem("initialBadgesCachedplayers" + steamID, JSON.stringify(Date.now()));
      localStorage.removeItem("player/badges/" + steamID)
    }
    var storageString = "player/badges/" + steamID;
    const badgesData = localStorage.getItem(storageString);
    if (badgesData) {
      const badges = JSON.parse(badgesData);
      return badges;
    } else {
      const badges = await trackPromise(fetch(URLPlayer + "badges/" + steamID).then(handleHttpErrors));
      localStorage.setItem(storageString, JSON.stringify(badges));
      return badges;
    }
  }


  getPlayerFromCache = async (steamID) => {
    const CachedTime = JSON.parse(localStorage.getItem("initialCachedplayers" + steamID));
    const timeExpired = CachedTime ? Date.now() - CachedTime : 0;
    //30 mins
    if (!CachedTime || timeExpired > 30 * 60000) {
      localStorage.setItem("initialCachedplayers" + steamID, JSON.stringify(Date.now()));
      localStorage.removeItem("player/" + steamID)
    }
    var storageString = "player/" + steamID;
    const playerData = localStorage.getItem(storageString);
    if (playerData) {
      const player = JSON.parse(playerData);
      return player;
    } else {
      const player = await trackPromise(fetch(URLPlayer + steamID).then(handleHttpErrors));
      localStorage.setItem(storageString, JSON.stringify(player));
      return player;
    }
  }

  getPlayerTimesFromCache = async (steamID, incrementer) => {
    const CachedTime = JSON.parse(localStorage.getItem("initialCachedplayersTimes" + steamID));
    const timeExpired = CachedTime ? Date.now() - CachedTime : 0;
    //30 mins
    if (!CachedTime || timeExpired > 30 * 60000) {
      localStorage.setItem("initialCachedplayersTimes" + steamID, JSON.stringify(Date.now()));
      localStorage.removeItem("playertimes/" + steamID)
    }
    var storageString = "playertimes/" + steamID;
    var playerTimesData = localStorage.getItem(storageString);
    if (incrementer === 0 && playerTimesData) {
      const cachedPlayerTimes = JSON.parse(playerTimesData)
      return cachedPlayerTimes;
    } else {
      const playertimes = await trackPromise(fetch(URLPlayerMaps + steamID + "/" + incrementer).then(handleHttpErrors));
      if (playerTimesData !== null) {
        const cachedPlayerTimes = JSON.parse(playerTimesData);
        playertimes.map(e => (
          cachedPlayerTimes.push(e)
        ))
        localStorage.setItem(storageString, JSON.stringify(cachedPlayerTimes));
        return cachedPlayerTimes;
      } else if (incrementer === 0) {
        localStorage.setItem(storageString, JSON.stringify(playertimes));
        return playertimes;
      }
    }
  }

  getMapTimesFromCacheSize = async (mapname, stage) => {
    const CachedTime = JSON.parse(localStorage.getItem("maptimessizecached" + mapname + stage));
    const timeExpired = CachedTime ? Date.now() - CachedTime : 0;
    //30 mins
    if (!CachedTime || timeExpired > 30 * 60000) {
      localStorage.setItem("maptimessizecached" + mapname + stage, JSON.stringify(Date.now()));
      localStorage.removeItem("mapsizecache" + mapname + stage);
    }
    var maptimesCacheSizeData = localStorage.getItem("mapsizecache" + mapname + stage);
    if (maptimesCacheSizeData) {
      const cachedsize = JSON.parse(maptimesCacheSizeData);
      return cachedsize;
    } else {
      const mapCacheSize = await fetch(URLMapsSize + "/" + mapname + "/" + stage).then(handleHttpErrors);
      localStorage.setItem("mapsizecache" + mapname + stage, JSON.stringify(mapCacheSize));
      return mapCacheSize;
    }
  }

  getMapTimesFromCache = async (mapname, stage, incrementer) => {
    const CachedTime = JSON.parse(localStorage.getItem("maptimescached" + mapname + stage));
    const timeExpired = CachedTime ? Date.now() - CachedTime : 0;
    //30 mins
    if (!CachedTime || timeExpired > 30 * 60000) {
      localStorage.setItem("maptimescached" + mapname + stage, JSON.stringify(Date.now()));
      localStorage.removeItem("map/" + mapname + stage);
    }
    var mapTimesData = localStorage.getItem("map/" + mapname + stage);
    if (incrementer === 0 && mapTimesData) {
      const cachedMapTimes = JSON.parse(mapTimesData);
      return cachedMapTimes;
    } else {
      const mapTimes = await trackPromise(fetch(URLMaps + mapname + "/" + stage + "/" + incrementer).then(handleHttpErrors));
      if (mapTimesData !== null) {
        const cachedMapTimes = JSON.parse(mapTimesData);
        mapTimes.map(e => (
          cachedMapTimes.push(e)
        ))
        localStorage.setItem("map/" + mapname + stage, JSON.stringify(cachedMapTimes));
        return cachedMapTimes;
      } else if (incrementer === 0) {
        localStorage.setItem("map/" + mapname + stage, JSON.stringify(mapTimes));
        return mapTimes;
      }
    }
  }

  getPlayersBySearchCache = async (keyword) => {
    const CachedTime = JSON.parse(localStorage.getItem("searchCache/time/" + keyword));
    const timeExpired = CachedTime ? Date.now() - CachedTime : 0;
    //30 mins
    if (!CachedTime || timeExpired > 30 * 60000) {
      localStorage.setItem("searchCache/time/" + keyword, JSON.stringify(Date.now()));
      localStorage.removeItem("searchCache/" + keyword)
    }
    var storageString = "searchCache/" + keyword;
    var searchData = localStorage.getItem(storageString);
    if (searchData) {
      const cachedSearch = JSON.parse(searchData)
      return cachedSearch;
    } else {
      const searchPlayers = await trackPromise(fetch(URLSearchPlayers + keyword).then(handleHttpErrors));
      localStorage.setItem(storageString, JSON.stringify(searchPlayers));
      return searchPlayers;
    }
  }
  getMapsBySearchCache = async(keyword) => {
    const CachedTime = JSON.parse(localStorage.getItem("searchCacheMaps/time/" + keyword));
    const timeExpired = CachedTime ? Date.now() - CachedTime : 0;
    //30 mins
    if (!CachedTime || timeExpired > 30 * 60000) {
      localStorage.setItem("searchCacheMaps/time/" + keyword, JSON.stringify(Date.now()));
      localStorage.removeItem("searchCacheMaps/" + keyword)
    }
    var storageString = "searchCache/" + keyword;
    var searchData = localStorage.getItem(storageString);
    if (searchData) {
      const cachedSearch = JSON.parse(searchData)
      return cachedSearch;
    } else {
      const searchMaps = await trackPromise(fetch(URLSearchMaps + keyword).then(handleHttpErrors));
      localStorage.setItem(storageString, JSON.stringify(searchMaps));
      return searchMaps;
    }
  }
};
export default new DataFacade();