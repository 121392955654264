import React, { Component } from 'react';
import { HashRouter as Router, Route } from "react-router-dom";

import Leaderboard from "./Leaderboard/Leaderboard";
import Player from "./Player/Player";
import Maps from "./Maps/Maps";
import MapBoard from "./MapBoard/MapBoard";

import './css/custom.css';
import './css/style.css';
import './css/vendors.css';

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            players: []
        };
    }

    setLeaderboard = (players) => {
        this.setState({ players })
    }

    render() {
        return (
            <React.Fragment>
                <Router>
                    <Route path={`/leaderboard`} render={(props) => {
                        return (<Leaderboard setLeaderboard={this.setLeaderboard} />)
                    }} />
                    <Route path={`/player/:steamid`} render={(props) => {
                        return (<Player {...props} />)
                    }} />
                    <Route path={`/map/:mapname/:stage`} render={(props) => {
                        return (<Maps {...props} />)
                    }} />
                    <Route path={`/mapboard`} render={(props) => {
                        return (<MapBoard {...props} />)
                    }} />
                </Router>
            </React.Fragment>
        )
    }

}
export default App;