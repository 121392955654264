import React, { Component } from "react";
import Facade from "../Datafacade/datafacade";
import { NavLink } from 'react-router-dom';

class MapsSearch extends Component {
    state = {
        maps: []
    };

    componentDidMount = async () => {
        const maps = await Facade.getMapsBySearchCache(this.props.keyword);
        this.setState({ maps });
    };

    componentDidUpdate = async prevProps => {
        if (this.props.keyword !== prevProps.keyword) {
            const maps = await Facade.getMapsBySearchCache(this.props.keyword);
            this.setState({ maps });
        }
    }
    render() {
        return (
            <React.Fragment>
                <div className="container mm">
                    <div className="row">
                        {this.state.maps.map(e => (
                            <div className="item col-12 col-sm-6 col-md-4 col-lg-3 d-flex justify-content-center" key={"mapdivs/" + e.mapname + "/" + e.mapstage}>
                                <div className="box_grid text-center">
                                    <NavLink to={'/map/' + e.mapname + "/" + e.mapstage}>
                                        <strong> Map: {e.mapname}</strong><br />
                                        <strong> Stage: {e.mapstage}</strong><br />
                                    </NavLink>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default MapsSearch;