import React from 'react';
import Facade from '../Datafacade/datafacade';
import { NavLink } from 'react-router-dom';
import Loader from 'react-loader-spinner';
import { usePromiseTracker } from "react-promise-tracker";
import '../css/style.css';
const LoadingIndicator = props => {
    const { promiseInProgress } = usePromiseTracker();
    return promiseInProgress &&
        <div
            style={{
                width: "100%",
                height: "100",
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
            }}
        >
            <Loader type="Circles" color="#2BAD60" height="100" width="100" />
        </div>
};
class Player extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            player: {},
            mapTimes: [],
            breakload: true,
            scrollListener: {},
            badgesTransfer: [],
        };
        this.scrollListener = this.handleScroll.bind(this);
    }

    componentWillMount = async () => {
        window.addEventListener('scroll', this.scrollListener);
        this.setState({ breakload: false })
        const steamid = this.props.match.params.steamid;
        const player = await Facade.getPlayerFromCache(steamid);
        const badgesTransfer = await Facade.getPlayerBadgesFromCache(steamid);
        const mapTimes = await Facade.getPlayerTimesFromCache(steamid, 0);
        this.setState({ player });
        this.setState({ badgesTransfer });
        this.setState({ mapTimes });
        this.setState({ breakload: true })
    };

    componentWillUnmount() {
        window.removeEventListener("scroll", this.scrollListener);
    }

    findLateIndex(array) {
        return array[array.length - 5];
    }

    handleScroll = () => {
        if (this.state.breakload && this.state.player.Times > this.state.mapTimes.length) {
            const map = this.findLateIndex(this.state.mapTimes);
            var lastLi = document.getElementById("playermaps/" + map.mapname + "/" + map.mapstage);
            var lastLiOffset = lastLi.offsetTop + lastLi.clientHeight;
            var pageOffset = window.pageYOffset + window.innerHeight;
            if (pageOffset > lastLiOffset) {
                this.setState({ breakload: false })
                this.loadMaps();
            }
        }
    };

    loadMaps = async () => {
        const steamid = this.props.match.params.steamid;
        const mapTimes = await Facade.getPlayerTimesFromCache(steamid, this.state.mapTimes.length);
        this.setState({ mapTimes });
        this.setState({ breakload: true })
    }


    render() {
        const badges = Object.keys(this.state.badgesTransfer).map(badges => (
            this.state.badgesTransfer[badges].map((badge) => {
                return <div key={badge}>
                    <img src={badge} alt="" /> <br />
                </div>
            })
        ));
        return (
            <React.Fragment>
                <section className="hero_single version_2 start_bg_zoom">
                    <div className="wrapper">
                        <div className="container">
                            <h3>Player Profile:  {this.state.player.name}</h3>
                        </div>
                    </div>
                </section>
                <div className="tickets-block" style={{ background_color: "#393f47" }}>
                    <div className="container-fluid">
                        <p className="text-center ">
                            <NavLink to={'/mapboard/'}>
                                <b>MapBoard</b>
                            </NavLink>
                        </p>
                        <p className="text-center ">
                            <NavLink to={'/leaderboard/'}>
                                <b>LeaderBoard</b>
                            </NavLink>
                        </p>
                    </div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
                <div className="box_grid text-center">
                    <a href={"http://steamcommunity.com/profiles/" + this.state.player.steamID64}>
                        <strong> {this.state.player.steamID}</strong><br />
                        <strong>{this.state.player.name}</strong><br />
                        <img src={this.state.player.Avatar} alt="" /> <br />
                        <strong>Rank: {this.state.player.Rank}</strong><br />
                        <strong>Points: {this.state.player.PlayerPoints}</strong><br />
                        <strong>Times: {this.state.player.Times}</strong><br />
                        {badges}
                    </a>
                    </div>
                </div>
                <div className="container mm">
                    <div className="row">
                        {this.state.mapTimes.map(e => (
                            <div className="item col-12 col-sm-6 col-md-4 col-lg-3 d-flex justify-content-center"
                                key={"playermapkey/" + e.mapname + "/" + e.mapstage} id={"playermaps/" + e.mapname + "/" + e.mapstage}>
                                <div className="box_grid text-center">
                                    <NavLink to={'/map/' + e.mapname + "/" + e.mapstage}>
                                        <strong> Map: {e.mapname}</strong><br />
                                        <strong> Stage: {e.mapstage}</strong><br />
                                        <strong> Position: {e.position}</strong><br />
                                        <strong> Time: {e.mapTime}</strong><br />
                                        <strong> MapPoints: {e.mapPoint}</strong><br />
                                    </NavLink>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                {!this.state.breakload ? <h1>
                    <LoadingIndicator />
                </h1> : ""}
            </React.Fragment>
        );
    }
}
export default Player;