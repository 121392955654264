import React, { Component } from "react";
import Facade from "../Datafacade/datafacade";
import { NavLink } from 'react-router-dom';
class playersSearch extends Component {
    state = {
        players: [],
    };

    componentDidMount = async () => {
        const players = await Facade.getPlayersBySearchCache(this.props.keyword);
        this.setState({ players });
    };

    componentDidUpdate = async prevProps => {
        if (this.props.keyword !== prevProps.keyword) {
            const players = await Facade.getPlayersBySearchCache(this.props.keyword);
            this.setState({ players });
        }
    }
    render() {
        return (
            <React.Fragment>
                <div className="container mm">
                    <div className="row">
                        {this.state.players.map(e => (
                            <div className="item col-12 col-sm-6 col-md-4 col-lg-3 d-flex justify-content-center" key={"item" + e.steamID} id={"itemPlayers/" + e.steamID}>
                                <div className="box_grid text-center">
                                    <NavLink to={'/player/' + e.steamID}>
                                        <strong> {e.steamID}</strong><br />
                                        <strong>{e.name}</strong><br />
                                        <figure>
                                            <img src={e.Avatar} className="img-fluid" alt={e.Avatar} /> <br />
                                            <div className="read_more">
                                                <span>Check Player</span>
                                            </div>
                                        </figure>
                                        <strong>Rank: {e.Rank}</strong><br />
                                        <strong>Points: {e.PlayerPoints}</strong><br />
                                        <strong>Times: {e.Times}</strong><br />
                                        <div id="test">
                                            {e.UrlBanners.map(banners => (
                                                <img src={banners} alt="" key={banners} />
                                            ))}
                                        </div>
                                    </NavLink>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default playersSearch;