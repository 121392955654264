import React from 'react';
import Facade from '../Datafacade/datafacade';
import { NavLink } from 'react-router-dom';
import Loader from 'react-loader-spinner';
import { usePromiseTracker } from "react-promise-tracker";
import { FormControl } from "react-bootstrap";
import MapsSearch from "../MapsSearch/mapsSearch";
import '../css/style.css';
const LoadingIndicator = props => {
    const { promiseInProgress } = usePromiseTracker();
    return promiseInProgress &&
        <div
            style={{
                width: "100%",
                height: "100",
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
            }}
        >
            <Loader type="TailSpin" color="#2BAD60" height="100" width="100" />
        </div>
};


class MapBoards extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            maps: [],
            breakload: false,
            keyword: "",
            searchBool: true,
        };
    }

    handleKeywordInput = e => {
        const searchTerm = e.target.value;
        this.setState({ keyword: searchTerm });
        this.setState({ searchBool: true });
    };

    handleSubmit = e => {
        e.preventDefault();
        this.setState({ searchBool: false });
    };

    componentWillMount = async () => {
        this.setState({ breakload: false })
        const maps = await Facade.getMapCountFromCache();
        this.setState({ maps });
        this.setState({ breakload: true })
    };

    render() {
        return (
            <React.Fragment>
                <section className="hero_single version_2 start_bg_zoom">
                    <div className="wrapper">
                        <div className="container">
                            <h3>UNLOZE Zombie Escape Mapboard</h3>
                            <p>
                                Browse Maps
                            </p>
                            <form>
                                <div className="row no-gutters custom-search-input-2">
                                    <div className="col-lg-8">
                                        <div className="form-group">
                                            <FormControl
                                                type="text"
                                                onChange={this.handleKeywordInput}
                                                value={this.state.k}
                                                placeholder="Search maps by name"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-2">
                                        <input
                                            type="submit"
                                            onClick={this.handleSubmit}
                                            className="btn_search"
                                            value="Search"
                                        />
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </section>
                <div className="tickets-block" style={{ background_color: "#393f47" }}>
                    <div className="container-fluid">
                        <p className="text-center ">
                            <NavLink to={'/leaderboard/'}>
                                <b>Leaderboard</b>
                            </NavLink>
                        </p>
                    </div>
                </div>
                {!this.state.breakload ? <h1>
                    <LoadingIndicator />
                </h1> : ""}
                {this.state.searchBool ? ("") : (<MapsSearch keyword={this.state.keyword} />)}
                <div className="container mm" style={{ marginTop: "85px" }}>
                    <div className="row">
                        {this.state.maps.map(e => (
                            <div className="item col-12 col-sm-6 col-md-4 col-lg-3 d-flex justify-content-center"
                                key={"mapdivs/" + e.mapname + "/" + e.mapstage} id={"maps/" + e.mapname + "/" + e.mapstage}>
                                <div className="box_grid text-center">
                                    <NavLink to={'/map/' + e.mapname + "/" + e.mapstage}>
                                        <strong> Map: {e.mapname}</strong><br />
                                        <strong> Stage: {e.mapstage}</strong><br />
                                    </NavLink>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
export default MapBoards;